html, body {
  max-width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
}

body {
  scroll-behavior: smooth;
  display: block;
}

p, h1, h2, h3, h4, h5, h6 {
  color: var(--text-color);
  margin: 0;
}

p {
  font-weight: 100;
  font-size: 18px;
  font-family: "Open Sans";
  font-weight: 400;

}

a {
  font-weight: 100;
  font-size: 18px;
}

.lower-font-size {
  font-size: 16px;
}

button {
  background-color: var(--btn-color);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  color: var(--text-color);
  padding: 12px 50px;
  font-size: 16px;
  cursor: pointer;
}

button:focus {
  border: none;
}

button:hover {
  transform: scale(1.05);
}

button:active {
  transform: scale(0.95);
}



header {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  gap: 100px;
  height: 80px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.185);
  background-color: transparent;
  box-shadow: none;
  transition: background-color 0.3s linear, ;
  z-index: 20;
}

header a {
  text-decoration: none;
  color: var(--text-color); 
}

header.scrolled {
  background-color: #152131;
  box-shadow: 0px 4px 9px #111a27;
}


header hr {
  width: 90%;
  margin-bottom: 0;
  margin-top: 5px;
}


/* Home */

.home {
  display: flex;
  justify-content: space-evenly;
  align-items: end;
  flex-wrap: wrap;
  flex-direction: row;
  padding-top: 80px;
  width: 100%;
  gap: 50px;
  height: auto;
  background: linear-gradient(308deg, #22344D 35%, #5277D6 35%);
  position: relative;
  z-index: 10;
}

@media (max-width: 786px) {

  header {
    gap: 50px;
  }

  .home {
    height: auto;
    background: linear-gradient(285deg, #22344D 38%, #5277D6 38%);
  }

  .home-text div p {
    text-align: center;
  }

  button {
    align-self: center;
  }

  .home-text {
    align-items: center;
  }

}

@media  (max-width: 500px) {
  .home-text h1 {
    font-size: 30px !important;
  }  
}

.home-imgs {
  position: relative;
}

.home-imgs img {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 5;
}

.home-text {
  margin-top: 50px;
  height: 100%;
  display: flex;
  padding-bottom: 100px;
  gap: 90px;
  text-wrap: nowrap;
  justify-content: center;
  flex-direction: column;
}

.home-text h1 {
  font-size: 40px;
}

.home-text button {
  font-family: 'Open Sans', sans-serif;
}

.home-text p {
  padding-top: 20px;
}

.my-name {
  margin-left: 135px;
}

.name-is, 
.my-name,
.webDev h1 {
  text-shadow: 6px 6px 3px var(--background-color-2);
}

.text-cursor {
  text-shadow: none !important;
}

.visible-back {
  z-index: 2;
  position: relative;
  background-color: none;
  width: 100px;
  height: 50px;
}

.other-back {
  position: relative;
  z-index: 5;
  background: none;
}

.webDev {
  gap: 10px;
}

.web-animation,
.webDev {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}

.text-cursor {
  font-size: 35px !important;
  padding-bottom: 10px;
}

.blink {
  color: transparent;
  z-index: 10;
}

/* @keyframes slideOutFade {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-340px);
  }
}

@keyframes slideInFade {
  0% {
    transform: translateX(-340px);
  }
  100% {
    transform: translateX(0);
  }
} */

.web-developer-animation {
  z-index: 4;
  text-wrap: nowrap;
  transition: transform 1s;
}

.visibleWebDev {

  animation: slideOutFade 1s linear;
}

.invisibleWebDev {

  animation: slideInFade 1s linear;
}

.hidden-box {
  z-index: 10;
  left: 0;
  width: 17%;
  height: 60px;
  position: relative;
  background-color: #5277D6;
}

.circle-1, .circle-2, .circle-3 {
  position: absolute;
  border-radius: 50%;
  display: inline-block;
  z-index: 1; 
}

.circle-1 {
  width: 140px;
  height: 140px;
  right: 60%;
  top: 2%;
  background-color: #22344d;
}

.circle-2 {
  width: 200px;
  height: 200px;
  right: 3%;
  top: 41%;
  background-color: #171F2E;
}

.circle-3 {
  width: 150px;
  height: 210px;
  border-top-right-radius: 210px;
  border-bottom-right-radius: 210px;
  right: 65%;
  top: 59%;
  background-color: #192030;
  overflow: hidden; 
  transform: rotate(245deg);
}

/* About  */

.about {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: auto;
  gap: 100px;
  padding: 100px 0;
  background-color: var(--background-color-3);
  position: relative;
  z-index: 10;
}



hr {
  color: var(--btn-color);
  border: 2px solid var(--btn-color);
  border-radius: 5px;
  width: 100%;
}


.about-images {
  width: auto;
  position: relative;
}

.about-me {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 30%;
}

.about-me p:first-child {
  font-size: 30px;
}

@media (max-width: 967px) {
  .about {
    flex-direction: column;
  }

  .about-me {
    max-width: 70%;

  }
}

@media (max-width: 500px) {
  .about {
    gap: 50px;
  }
}


.about-images {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 400px; /* Increased for visual clarity */
  width: 400px; /* Increased for visual clarity */
}

.about-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.orbit {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: orbitRotate 25s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-images-about {
  position: absolute;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}



.css, .js, .html, .ts {
  /* margin: 10px; */
  width: 40px; 
  height: 50px; 
  animation: spin 25s linear infinite;
}

.react-back {
  background-color: #00d9ff69;
}

.css-back {
  background-color: #264ce469;
}

.js-back {
  background-color: #F1BF2669;
}

.html-back {
  background-color: #F1652969;
}

.ts-back {
  background-color: #1478be69;
}

.react {
  width: 45px; 
  height: 45px; 
  animation: spin 25s linear infinite;
}

.react-back { transform: rotate(0deg) translateX(200px) rotate(0deg); }
.css-back { transform: rotate(72deg) translateX(200px) rotate(-72deg); }
.js-back { transform: rotate(144deg) translateX(200px) rotate(-144deg); }
.html-back { transform: rotate(216deg) translateX(200px) rotate(-216deg); }
.ts-back { transform: rotate(288deg) translateX(200px) rotate(-288deg); }

@keyframes orbitRotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes spin {
  from { transform: rotate(360deg); }
  to { transform: rotate(0deg); }
}

.about-tagline {
  font-size: 20px;
  position: absolute;
  z-index: 1;
  text-align: center;
}

@media (max-width: 900px) {

  .react-back { transform: rotate(0deg) translateX(170px) rotate(0deg); }
  .css-back { transform: rotate(72deg) translateX(170px) rotate(-72deg); }
  .js-back { transform: rotate(144deg) translateX(170px) rotate(-144deg); }
  .html-back { transform: rotate(216deg) translateX(170px) rotate(-216deg); }
  .ts-back { transform: rotate(288deg) translateX(170px) rotate(-288deg); }

}


@media (max-width: 500px) {

  .css, .js, .html, .ts {
    width: 33px; 
    height: 40px; 
  }

  .react {
    width: 40px; 
    height: 40px; 
  }

  .about-images {
    width: 200px;
  }
  .circle-images-about {
    width: 70px;
    height: 70px;
  }

  .react-back { transform: rotate(0deg) translateX(120px) rotate(0deg); }
  .css-back { transform: rotate(72deg) translateX(120px) rotate(-72deg); }
  .js-back { transform: rotate(144deg) translateX(120px) rotate(-144deg); }
  .html-back { transform: rotate(216deg) translateX(120px) rotate(-216deg); }
  .ts-back { transform: rotate(288deg) translateX(120px) rotate(-288deg); }

  .about-tagline {
    font-size: 13px;
  }

}



/* Work  */

.work {
  padding: 60px 5%;
  width: auto;
  height: auto;
  display: flex;
  background-color: var(--background-color-4);
  flex-direction: column;
  align-items: center;
  gap: 45px;
}

.work-form {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.title {
  font-size: 30px;
}

.sub-title {
  display: flex;
  width: 20%;
  flex-direction: column;
  text-wrap: nowrap;
}


.empty-space {
  width: 40%;
}

.work-projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.project {
  display: flex;
  flex-direction: column;
}

.work-image {
  position: relative;
  overflow: hidden;
  width: 310px; /* Adjusted to your specific size */
  height: 200px;
  padding-bottom: 70px;
}

.work-image img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  transition: filter 0.3s;
}

.work-image:hover img {
  filter: blur(3px);
}

.work-image button {
  position: absolute;
  border: none;
  color: white;
  bottom: -60px;
  left: 15%;
  width: 70%;
  z-index: 2;
  transition: bottom 0.3s, background-color ease-in-out 0.1s;
}

.work-image:hover button {
  bottom: 20px;
}

.work-image button:hover {
  color: #fff;
  bottom: 20px;
  transition: color 0.3s;
  transition: background-color 0.3s ;
}


.work-image {
  width: 310px;
  height: 200px;
  border-radius: 5px;
}

.project p {
  padding-bottom: 15px;
}

.two-btn {
  display: flex;
  justify-content: center;
  width: 100%;
}

.two-btn a button{
  width: 35%;
}

.github-btn { left: 10% !important; }
.demo-btn{ left: 55% !important; }

.github-btn,
.demo-btn {
  padding: 12px 0;
}


@media (max-width: 967px) {

  .orbit {
    width: 0%;
    height: 0%;
  }

  .title {
    text-wrap: wrap;
  }

  .work {
    align-items: center;
  }

  .work-projects {
    justify-content: center;
  }

  .sub-title {
    width: 100%;
  }

}


/* Contact  */


.contact {
  padding: 60px 0px;
  background-color: var(--background-color-5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 17px;
}

.contact-form {
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 17px;
}

.contact textarea {
  width: auto;
  height: 180px;
}
.contact .sub-title {
  width: 40%;
}

.contact-info input,
.contact textarea {
  font-family: sans-serif;
  border-radius: 5px;
  border: 1px solid var(--background-input);
  background-color: var(--background-input);
  color: #fff;
  resize: none;
  padding: 14px;
  font-size: 16px;
  outline: none;
}

.contact-info input {
  width: 50%;
}

.contact button {
  margin-top: 25px;
}


.contact-info input:focus {
  border: 1px solid var(--btn-color);
}

.contact-info input::placeholder {
  color: #ffffffb3;
}

.contact textarea:focus {
  border: 1px solid var(--btn-color);
}

.contact textarea::placeholder{
  color: #ffffffb3;
}

@media (max-width: 768px) {
  .contact .sub-title {
    width: 100%;
  }
}

/* Footer  */

footer {
  background-color: var(--background-color-3);
  padding: 55px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

footer > div, 
footer > p {
    text-align: start;
}

.footer-elements {
  width: 70%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}

.footer-contact {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  gap: 40px;
}

.footer-phone-email {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer-elements a{
  color: var(--btn-color);
  text-decoration: none;
}


.footer-links {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-direction: row;
}

.footer-links div  {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.footer-links div svg {
  cursor: pointer;
  width: 20px;
  height: 20px;
  padding: 10px;
  border-radius: 50%;
  background-color: #00000063;
  color: #fff;
}

.footer-links div svg:hover{
  color: var(--btn-color);
}



@media (max-width: 768px) {
  .footer-elements {
    gap: 50px;
    width: 100%;
    flex-direction: column;
  }

  .footer-links {
    gap: 15px;
  }

  .footer-contact {
    align-items: center;
    flex-direction: column;
  }
}

@media (max-width: 500px) {
  .contact-info {
    flex-direction: column;
  }

  .contact-info input {
    width: auto;
  }

  .contact button {
    width: 100%;
  }
}