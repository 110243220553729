body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Open Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


:root {
  --text-color: #FFFFFF;
  --background-color-1: #5277D6;
  --btn-color: #EE6C4D;
  --background-color-2: #22344D;
  --background-color-3: #293241;
  --background-color-4: #2F3746;
  --background-color-5: #3D5A80;
  --background-input: #26384F;
}
